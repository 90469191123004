@import "_variables";

/* Fonts */
/*
	font-family: $Titillium;
*/

/* Media Screens */
.container {
 width: 970px;
 margin: 0 auto;
	body & {
		@include break(large) {
			width: 1310px;
		}
	}
  @include break(desktop) {
		width: 1170px;
	}
	@include break(neutral) {
		width: 721px;
	}
	@include break(mobile) {
		width: 100%;
	}
	@include break(x-small) {
		width: 100%;
		min-width: 320px;
	}
}
html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #fff;
}
body {
	@include fz(20px, 1.5, #1c1c1c, 400);
	font-family: $Titillium;
	@include break(medium) {
	}
	@include break(x-small) {
		min-width: 320px;
		font-size: 14px;
	}
}
.site-container {
	max-width: 3000px;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	@include break(custom) {
	}
	@include break(medium) {
	}
	@include break(neutral) {
	}
	@include break(mobile) {
	}
}



/**   nav menu  **/

#nav {
	 background: #004188;
	 background-image: linear-gradient(to left, #0076bd, #004188);
	
	@include break(medium) {
		float: none;
		width: 100%;
		clear: both;
	}
	@include break(mobile) {
		display: none;
	}
	@include clearfix();

	.sf-menu {
		position: relative;
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		@include break(custom) {
		}
		@include break(medium) {
		}

		& > li {
			margin: 0;
			position: relative;
			display: inline-block;
			font-size: 0;
			line-height: 0;
			padding: 0 40px 0 0 ;
			@include break(neutral) {
				padding: 0 20px 0 0;
			}
			& > a {
				@include fz(18px, 1.1, #e2e2e2, 400);
				text-decoration: none;
				display: inline-block;
				font-family: $Titillium;
				
				text-transform: uppercase;				
				padding: 12px 10px 10px;
				border-bottom: 4px transparent solid;
				@include break(custom) {
					font-size: 16px;
				}
				@include break(medium) {
				}
				@include break(neutral) {
					font-size: 15px;
				}
			}


			&.sfHover,
			&:hover { 
				& > a {
					color: #fff;
					border-color:#d0021b;
				}
			}  
			& > a.current-menu-item {
				color: #fff;
				font-weight: 700;
			}
		}
		i {
			font-family:'FontAwesome';
			font-style: normal;			
		}

		ul.dd_menu {
			position: absolute;
			min-width: 280px;
			left:-2px;
			top: 100%;
			background: rgba(0, 0, 0, .8);
			padding: 12px 8px 6px; 
			z-index: 100;
			border-radius: 5px;
			display: none;			
			text-align: left;
			
			li {
				padding: 2px 0;
				a {
					display: block;
					text-decoration: none;
					@include fz(15px, 1.4, #c7be94, 400);
					padding: 2px 12px;
					@include break(medium) {
						font-size: 12px;
					}
					&:hover {
						color: #fffbe2;
						background: #573c23;
					}
				}
			}
			li + li {
				border-top: 1px #543f2c dashed;
			}
		}
	}
}




/**     H E A D E R  **/
header.site-header {
	position: relative;
	//z-index: 1000;
	border-top: 4px #004188 solid;
	@include break(neutral) {
	}
	@include break(mobile){
		margin-top: 40px;
		border-top: 0;
	}
	#wrapper {
		position: relative;
		//z-index: 1000;
		overflow: hidden;
		padding-top: 5px;		
	  @include break(neutral) {
	  	padding-top: 0;
		}
	  @include break(mobile) {
		}
	}
	.phone {
		float: right;
		padding-right: 40px;
		padding-top: 20px;
	  @include break(neutral) {
	  	padding-top: 10px;
		}
	  @include break(mobile) {
	  	position: fixed;
	  	left: 65px;
	  	top: 0;
	  	z-index: 1100;
	  	padding-top: 9px;
		}
		img {
			float: left;
			margin-right: 10px;
			padding-top: 5px;
		  @include break(mobile) {
		  	display: none;		  	
		  }
		}
		p {
			overflow: hidden;		
		  @include break(mobile) {
		  	margin-bottom: 0;
		  	padding-bottom: 0;
		  }			
		}
		span {
			text-transform: uppercase;
			@include fz(18px, 1.42, #9b9b9b, 400);			
			display: block;			
		  @include break(mobile) {
		  	display: none;		  	
		  }
		}
		a.tel {
			@include fz(26px, 1.2, #0065ac, 700);						
			white-space: nowrap;			
		  @include break(mobile) {
		  	font-size: 18px;
		  	color: #fff;
		  }
		}

	}
	.top-btn {
		float: right;
		padding-top: 25px;
	  @include break(neutral) {
	  	padding-top: 15px;
		}
	  @include break(mobile) {
	  	padding-top: 0;
	  	position: fixed;
	  	right:0;
	  	top: 0;
	  	z-index: 210;
	  	margin-bottom: 0;
	  	padding-bottom: 0;
		}
		a.btn {
		  @include break(mobile) {
		  	font-size: 13px;
		  	padding: 0 20px;
		  	line-height: 36px;
		  	height: 40px;
		  }
		}
	}


	.logo {
		position: relative;
		float: left;
		padding-top: 12px;
		padding-bottom: 20px;
		z-index: 0;
	  @include break(custom) {
		}
	  @include break(medium) {
		}
	  @include break(neutral) {
	  	width: 240px;
		}

	  @include break(mobile) {
	  	float: none;
	  	margin-left: auto;
	  	margin-right: auto;
	  	display: block;	  	
	  	width: 280px;
	  	padding-top: 15px;
		}
	  @include break(x-small) {
		}
	}

}


/* E N D  H E A D E R */


/**     S L I D E R        **/

#slider {
	background-size: 100% 100%;
	position: relative;	
	overflow: hidden;	
	@include break(medium) {
	}

	.captions {
		position: absolute;
		left:0;
		top: 0;
		width: 100%;
		text-align: center;	
		height: 100%;
		@include break(mobile) {
			position: relative;
		 background: #004188;
		 background-image: linear-gradient(to left, #0076bd, #004188);
		 padding: 35px 20px;	
		}

		p {
			top: 50%;
			transform: translateY(-55%);
			position: absolute;
			text-align: center;
			width: 100%;
			margin: 0;
			padding: 0;
			@include break(mobile) {
				position: relative;
				top: 0;		
				transform: translateY(0);
			}
			strong {
				display: block;
				text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
			  @include fz(58px, 1.4, #fff, 700);			
				@include break(custom) {
					font-size: 48px;
				}
				@include break(medium) {
					font-size: 36px;
				}		
				@include break(mobile) {
					font-size: 30px;
				} 	
			}
			span {
				display: block;
				text-transform: uppercase;
				text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
			  @include fz(30px, 1.4, #fff, 400);			
			  padding-bottom: 15px;
				@include break(medium) {
					font-size: 24px;
				}		
				@include break(mobile) {
					text-transform: none;
				} 	
			}
			a.btn {
				@include break(mobile) {
					width: 100%;
					font-size: 16px;
				} 	
			}
		}	 

	}

	.flex-control-nav.flex-control-paging {
		position: absolute;
		bottom: 30px;
		width: 100%;
		left:0;
		z-index: 101;
		text-align: center;		
	  @include break(neutral) {
	  	bottom: 10px;
		}
	  @include break(mobile) {
	  	display: none;	  	
		}

		li {
			display: inline-block;
			text-indent: -9999px;
			padding: 0 8px;
			a {
				display: block;
				background-color: #fff;
  			opacity: .3;
  			width: 14px;
  			height: 14px;
 				border-radius: 50%;
 				border: 2px transparent solid;
 				position: relative;
 				
  			&:hover,
  			&.flex-active {
  				background: #004188;
  				border-color:#fff;
  				opacity: 1;
  				&::after {
  					content: "";
  					display: block;
  					position: absolute;
  					width: 6px;
  					height: 6px;
						left:2px;
						top: 2px;
						background: #fff;
		 				border-radius: 50%;
  				}
  			}
			}
		}
	}

	.flex-direction-nav {
		display: none;		
	}

	&:hover {
		ul.flex-direction-nav {
			opacity: 1;
		}
	}

	.slides {
		li {
			display: none;
			img {
				width: 100%;
			}			
		}
	}

}
.flexslider {
	position: relative;
	overflow: hidden;	
	background: #004188;
}


/* SUB PAGE   */
.sub-page {
	.content {
		h1 {
			text-align: left;
			&.blank {
				@include break(mobile) {
					display: none;					
				} 					
			}
		}
		 
		h2 {
			color: #1d1d1d;
			@include break(medium) {
				font-size: 24px;
			}		
			@include break(neutral) {
				font-size: 20px;
			} 	
			@include break(mobile) {
				//font-size: 16px;
			} 	
		}
		h1 + h2 {
			padding-top: 0;
			margin-top: 0;
		}

		h3 {
		 	margin: 0;
		 	padding: 10px 0 20px 0;
		  @include fz(26px, 1.42, #1c1c1c, 400);		
		}

		
		h4 {
		 	margin: 0;
		 	padding: 10px 0 0px 0;
		  @include fz(20px, 1.42, #1c1c1c, 700);		
			@include break(medium) {
				font-size: 16px;
			}		

		}


		p {
			text-align: left;
		}

		.call {
		 	padding: 20px 0 20px 0;
		  @include fz(28px, 1.42, #1c1c1c, 400);			
			@include break(medium) {
				padding-top: 15px;
			}		
			@include break(mobile) {
				font-size: 20px;
			}		

		  a {
				font-weight: 700;
		  }
		}

		.left_inner {
			padding-left: 45px;
			@include break(mobile) {
				padding-left: 0;
			} 	

		}
		.right_inner {
			padding-right: 45px;
			@include break(mobile) {
				padding-right: 0;
			} 				
		}
		.right {
			float: right;
			@include break(mobile) {
				float: none;
			} 	

		}


	}

	.sub-header {
		position: relative;		
		background: #004188;
		& > img {
			width: 100%;
		}
		& > .wrapper {
			position: absolute;
			height: 100%;
			top: 0;
			width: 100%;

			p {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);				
			  @include fz(30px, 1.42, #fff, 600);			
			  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
				@include break(medium) {
					font-size: 24px;
				}		
				@include break(mobile) {
					font-size: 18px;
					line-height: 24px;
				}		
				@include break(x-small) {
					font-size: 16px;
					line-height: 20px;
				}		
			}
		}
	}

	.contacts {
		p {
			b {
				display: block;				
				font-weight: 400;
			}	
			a.mail {
				display: block;			
				margin-bottom: 25px;
				@include break(medium) {
					font-size: 14px;
				}		
			}
			a.tel {
				display: block;
				text-decoration: none;											
			}

		}
	}

}


/**     C  O  N  T  E  N  T         **/
.content {
	background: #fff url("../img/bg-content.png") right top no-repeat;
	padding: 40px 0;

 h1 {
 	margin: 0;
 	padding: 10px 0 20px 0;
  @include fz(36px, 1.42, #004188, 700);			
  text-align: center;  
	@include break(medium) {
		font-size: 30px;
		padding-bottom: 8px;
	}		
	@include break(mobile) {
		font-size: 26px;
		line-height: 1.2;
	}		
 }

	p {
		text-align: center;		
	  @include fz(20px, 1.42, #1c1c1c, 400);	
	  max-width: 1000px;
	  margin-left: auto;
	  margin-right: auto;	  						
		@include break(medium) {
			font-size: 17px;
		}		
	}

	.content-images {
		padding:  45px 0 0 0 ;
		img {
			width: 32.45%;
			@include break(neutral) {
				width: 32.4%;
			} 	
			@include break(mobile) {
				width: 32%;
			} 	
		}
		img + img {
			margin-left: 0.75%;
		}
	}
}


/**     B L U E          **/
.blue {
 background: #004188;
 background-image: linear-gradient(to left, #0076bd, #004188);
 padding:  50px 0;

	@include break(mobile) {
		padding: 32px 0;
		text-align: center;		
	} 	

 .photo {
 	border:10px #fff solid;
 	float: right;
	@include break(neutral) {
		border-width: 4px;
	} 	
	@include break(mobile) {
		margin-left: auto;
		margin-right: auto;
		float: none;
		margin-top: 20px;
	} 	
 }
 h2 {
 	margin: 0;
 	padding: 10px 0 20px 0;
  @include fz(28px, 1.42, #fff, 700);			
	@include break(medium) {
		font-size: 24px;
		padding-bottom: 12px;
		padding-top: 4px;
	}		
	@include break(neutral) {
		font-size: 19px;
	} 	
	@include break(mobile) {
		font-size: 18px;
		text-align: center;		
	} 	

 }

 p {
 	padding-right: 45px;
 	padding-bottom: 15px;
  @include fz(20px, 1.42, #fff, 400);			
	@include break(medium) {
		font-size: 16px;
		padding-right: 20px;
	}		
	@include break(neutral) {
		font-size: 15px;
	} 	
	@include break(mobile) {
		text-align: center;		
		padding-bottom: 5px;
	} 	
 }

}


/**     F O O T E R        F O O T E R         F O O T E R         **/
footer.site-footer {
	background: #f2f2f2;
	padding: 60px 0;
	@include break(custom) {
		padding: 50px 0;
	}		
	@include break(medium) {
		padding: 40px 0;
	}		
	@include break(mobile) {
		padding: 30px 0;
	}		
	@include break(x-small) {
	}		

	.col-sm-7.col-md-7 {
		@include break(mobile) {
			width: 400px;
			margin-left: auto;
			margin-right: auto;			
		}		
		@include break(x-small) {
			display: none;			
		}		

	}

	a.logo-footer {
		width: 250px;
		display: block;
		@include break(medium) {
		}		
		@include break(mobile) {
			margin-left: auto;
			margin-right: auto;
			display: block;			
			padding-bottom: 25px;
		}		
		@include break(x-small) {
			padding-bottom: 0;
		}		

	}
	p.bottom-btn {
		float: right;
		padding-top: 4px;
		@include break(mobile) {
			padding-top: 8px;
		}		
	}
	.phone {
		float: right;
		padding-right: 25px;
		img {
			float: left;
			margin-right: 10px;
			padding-top: 5px;
		}
		p {
			overflow: hidden;		
		}
		span {
			text-transform: uppercase;
			@include fz(18px, 1.42, #9b9b9b, 400);			
			display: block;			
		}
		a.tel {
			@include fz(26px, 1.2, #0065ac, 700);						
			white-space: nowrap;			
		}
	}


}

/*-------------------    Copyright  --------------------*/
.copyright {
	padding: 10px 0 5px;
	background: #e3e3e3;
	img {
		display: block;
		float: right;
		margin-left: 20px;
		margin-top: -22px;
		@include break(mobile) {
			margin-left: 10px;
		}		
	}
	p {
		@include fz(14px, 2, #1c1c1c, 400);			
		@include break(mobile) {
			float: none!important;
			text-align: center;
			width: 100%;			
			&.pull-right {
				width: 212px;
				margin-left: auto;
				margin-right: auto;					
			}				
		}		
		@include break(x-small) {
			width: 100%;
			float: none!important;
			text-align: center;
			font-size: 12px;
			&.pull-right {

			}				
		}		

	}
	a {
		color: #1c1c1c;
		text-decoration: underline;		
	}
}	



.paddcontent {
	padding-bottom: 32px;
	@include break(mobile) {
		padding-bottom: 15px;
	}		
}

/* Buttons */
.btn:active, .btn:focus,
.btn {
	@include link_trans();
	@include fz(16px, 1.4em, #fff, 700);
	margin: 0;
	text-decoration: none;
	background: none;
	position: relative;
	z-index: 10;
	display: inline-block;
 	padding: 12px 20px;
	border-radius: 0px;
	text-align: center;
	background: #cd1c32;
	text-transform: uppercase;
	text-shadow:none;
	&:hover {
		color: #fff;
		background: #002854;
	}
	
	@include break(medium) {
	}
	@include break(neutral) {
		font-size: 14px;
		padding: 10px 18px;
	}
	@include break(mobile) {
		font-size: 12px;
	}
	@include break(x-small) {	}
}


.more:active, .more:focus,
.more {
	@include link_trans();
	@include fz(16px, 1.4em, #fff, 700);
	margin: 0;
	text-decoration: none;
	background: none;
	position: relative;
	z-index: 10;
	display: inline-block;
 	padding: 12px 25px;
	border-radius: 0px;
	text-align: center;
	text-transform: uppercase;
	text-shadow:none;
	border: 1px #c9c9c9 solid;
	margin-right: 12px;
	&:hover {
		border-color: #fff;
		color: #004188;
		background: #fff;
	}
	
	@include break(medium) {
	}
	@include break(neutral) {
		font-size: 14px;
		margin-right: 4px;
		padding: 10px 18px;
	}
	@include break(mobile) {
		font-size: 12px;
	}
	@include break(x-small) {	}
}



/**     F O R M   S I D E B A R   **/

#ajax-contact-form {
	text-align: left;
	padding-bottom: 20px;
	p {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	label {
		margin-bottom: 0;
	}

}
#ajax-contact-form textarea,
#ajax-contact-form select,
#ajax-contact-form input {
	padding: 5px 12px 4px;
	background: none;
	width: 100%;
	border-radius: 0;
	@include fz(13px, 1.4em, #c7be94, 300);
	margin: 0 0 6px;
  border: solid 1px #5c5947;
	font-family: $Titillium;
	
}

#ajax-contact-form textarea:active,
#ajax-contact-form input:active,
#ajax-contact-form select:active,
#ajax-contact-form select:focus,
#ajax-contact-form textarea:focus,
#ajax-contact-form input:focus {
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #d2d2d2;
	color: #fff;
	outline: none;
}
#ajax-contact-form textarea {	height: 160px; }
#ajax-contact-form *::-webkit-input-placeholder {color:#c7be94; font-family: $Titillium; }
#ajax-contact-form *::-moz-placeholder          {color:#c7be94; font-family: $Titillium; }/* Firefox 19+ */
#ajax-contact-form *:-moz-placeholder           {color:#c7be94; font-family: $Titillium; }/* Firefox 18- */
#ajax-contact-form *:-ms-input-placeholder      {color:#c7be94; font-family: $Titillium; }

#ajax-contact-form *:focus::-webkit-input-placeholder {color:#c7be94; font-family: $Titillium; }
#ajax-contact-form *:focus::-moz-placeholder          {color:#c7be94; font-family: $Titillium; }/* Firefox 19+ */
#ajax-contact-form *:focus:-moz-placeholder           {color:#c7be94; font-family: $Titillium; }/* Firefox 18- */
#ajax-contact-form *:focus:-ms-input-placeholder      {color:#c7be94; font-family: $Titillium; }


#button_submit,
#fields .submit {
	@include link_trans();
	@include fz(16px, 36px, #ebe2b4, 700);
	font-family: $Titillium;
		
	margin: 4px 0 0 0;
	text-decoration: none;
	background: none;
	position: relative;
	z-index: 10;
	display: inline-block;
 	padding: 2px 8px;
	border-radius: 0;
	text-align: center;
	background: none;
	width: 120px;
	border:1px #fffbe7 solid;

	@include break(medium) {
		font-size: 14px;
	}
	@include break(neutral) {
		max-width: 240px;
		margin-left: auto;
		margin-right: auto;
		float: none;
	}
	@include break(mobile) {
		line-height: 20px;
	}
	@include break(x-small) {
		font-size: 11px;
	}
	&:hover {
		color: #090402;
		background: #e6c657;
		border-color:#e6c657;
	}
}
.notification_ok {
	padding-bottom: 15px;
	color: #000;
	line-height: 1.5em;
	font-size: 18px;
	text-align: left;
	color: #27ade3;
	padding-top: 15px;
}

.notification_error {
	color: #e54423;
	clear: both;
	text-align: left!important;
	font-size: 12px;
	line-height: 1.5em;
	margin-top: -10px;
	font-weight: 700;
}
#note {
	clear: both;
}
#rc-imgelect, .g-recaptcha {
	width: 250px;
  transform:scale(0.82);
  transform-origin:0 0;
  float: left;
	@include break(custom){
	  transform:scale(0.74);
	  transform-origin:0 0;
	}
	@include break(medium){
	  transform:scale(0.60);
	  transform-origin:0 0;
	}
	@include break(neutral){
	  transform:scale(.8);
	  transform-origin:0 0;
	}
	@include break(x-small){
		float: none;
	}

}
img {
		height: auto;
		max-width:100%;
	}
.clearfix {
	clear:both;
}
.center {
	text-align: center;
	margin-left: auto;
	margin-right: auto;	
}

#ajax-contact-form label.error {
	color: #f00;
	font-size: 12px;
	margin-top: -8px;
	display: inline-block;
	font-weight: 400;
	clear:both;
}

.mobile-none {
	@include break(neutral){
		display: none;
	}
}
div#button_submit {
	padding-left: 10px;
	padding-right: 10px;
	font-size: 12px;
}

/**     mobile menu       mobile menu   mobile menu      mobile menu        **/

//colors
$color_0: rgba(255, 255, 255, 0.75);
$color_btn: #222;
$color_text: #fff;
$color_shadow: #000;
$color_hover_bg: #ccc;
$color_hover_text: #222;

.slicknav_menu {
	font-size: 16px;
	box-sizing: border-box;
	background: #004188;
	background-image: linear-gradient(to left, #0076bd, #004188);
	display: none;
	z-index: 200;
	position: fixed;
	top: 0;
	width: 100%;
	left:0;
	height: 40px;

	@include break(mobile){
		display: block;
	}
	.phone-mobile {
		@include fz(16px, 28px, #fff, 300);
		float: left;
		padding-left: 10px;
		padding-top: 6px;
	  @include break(mobile) {

		}

		a {
			@include fz(16px, 28px, #fff, 300);

		}
		span {
			@include break(x-small){
				display: none;
			}
		}
	}
	* {		box-sizing: border-box;	}
	.slicknav_menutxt {
		color: $color_text;
		font-weight: bold;
		text-shadow: 0 1px 3px $color_shadow;
	}
	.slicknav_icon-bar {
		background-color: #f5841f;
	}
}
.slicknav_nav {
	color: $color_text;
	margin: 0;
	padding: 0;
	font-size: 0.875em;
	list-style: none;
	overflow: hidden;
	background: #004188;
	z-index:9999;
	.slicknav_row {
		&:click,
		&:active,
		&:focus {
			color: #fff;
			outline:none;
		}
	}
	a {
			@include fz(13px, 36px, #fff, 400);
			text-align: left;
			text-decoration: none;
			border-top: 1px #0076bd dashed;
			padding-bottom: 1px;
			text-transform: uppercase;
			
			padding-left: 8px;
			@include link_trans();
			&:hover {
				color: #fff;
			}
		& + i {
			@include break(mobile) {
				display: none;
			}
		}
	}

	a > a {
		border-top: 0;
	}
	.current-menu-item a {		color: #fff;	}
	.slicknav_txtnode {		margin-left: 15px;	}
	.slicknav_item a {		padding: 0;		margin: 0;	}
	.slicknav_parent-link a {		padding: 0;		margin: 0;	}

	em, i {
		display: none;		
	}
}
.slicknav_brand {
	color: $color_text;
	font-size: 18px;
	line-height: 30px;
	padding: 7px 12px;
	height: 44px;
}
.slicknav_btn {
	span {
		display: none;
	}
	display: block;
	margin: 0;
	padding: 0;
	text-decoration: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	background: url("../img/mobile-button.png") 50% 2px no-repeat;
	background-size: 39px auto;
	float: left;
	margin: 0;
	padding: 0;
	top: 0;
	left:10px;
	position: relative;
	@include link_trans();
	&.slicknav_open {
		background-position: 50% -34px;
	}
}

.slicknav_menu {
	*zoom: 1;
	.slicknav_menutxt {
		display: block;
		line-height: 1.188em;
		float: left;
	}
	.slicknav_icon {
		float: left;
    width: 1.125em;
    height: 0.875em;
		margin: 0.188em 0 0 0.438em;
        &:before {
            background: transparent;
            width: 1.125em;
            height: 0.875em;
            display: block;
            content: "";
            position: absolute;
        }
	}
	.slicknav_no-text {
		margin: 0;
	}
	.slicknav_icon-bar {
		display: block;
		width: 1.125em;
		height: 0.125em;
		-webkit-border-radius: 1px;
		-moz-border-radius: 1px;
		border-radius: 1px;
		-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
	}
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.slicknav_nav {
	clear: both;
	ul {
		list-style: none;
		overflow: hidden;
		padding: 4px 0 4px 0;
		margin: 0;
		border-top: 1px #543f2c dashed;
		background: #573c23;
		li {
			a {
				text-transform: capitalize;
				line-height: 20px;
				color: #c7be94;
				border: none;
				font-size: 13px;
				font-weight: 400;
				padding-left: 15px;
				display: block;
				&:hover {
					color: #fff;
				}
			}
		}
	}
	.slicknav_arrow {
		font-size: 0.8em;
		font-family: 'FontAwesome';
		float: right;
		margin-right: 20px;
	}
	.slicknav_item {
		cursor: pointer;
		a {
			display: inline;
			border-bottom: none;
			&:click,
			&:active,
			&:focus {
				color: #fff;
				outline:none;
			}

		}
	}
	.slicknav_row {
		display: block;
		&:click,
		&:active,
		&:focus {
			color: #fff;
			outline:none;
		}
	}
	a {		display: block;	}
	.slicknav_parent-link a {
		display: inline;
	}
}

/**     O T H E R       **/
a{color:#000; text-decoration:underline;}
a:focus{color:#000;text-decoration:none;}
a:hover{text-decoration:none;color:#000}

.scrolloff {
  pointer-events: none;
}
.map {
	position: relative;
	padding-bottom: 50%;
	padding-top: 30px;
	height: 0;
	background: #ccc;
	margin-bottom: 15px;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
	}
}
ul.list {
	padding: 10px 0 12px 0;
	margin: 0;
	overflow: hidden;
	li {
		padding-left: 25px;
		position: relative;		
		padding-bottom: 5px;
		@include fz(16px, 1.4, #242424, 400);
		&::after {
			position: absolute;
			width: 8px;
			height: 8px;
			content: '';
			border-radius: 50%;
			left: 2px;
			top: 8px;
	    background-color: #0076bd;
    }
	}

}

a[href*="tel"]{text-decoration:none; cursor: default;};
::selection{background: #160c05!important;color:#fff};
::-moz-selection{background: #160c05!important;color:#fff};